import React, { useEffect, useState } from 'react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import './LandingPage.css';
import { useWallet } from '@solana/wallet-adapter-react';

const apiBaseUrl = process.env.NODE_ENV === 'production' ? 'https://starwarscat.xyz' : 'http://localhost:5000';

const formatPublicKey = (publicKey) => {
    return `${publicKey.slice(0, 4)}...${publicKey.slice(-4)}`;
};

const formatTime = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${hours}h ${minutes}m ${seconds}s`;
};

const LandingPage = () => {
    const { publicKey } = useWallet();
    const [counter, setCounter] = useState(0);
    const [imagePosition, setImagePosition] = useState({ top: '50%', left: '50%' });
    const [currentImage, setCurrentImage] = useState('customImage2');
    const [stats, setStats] = useState([]);
    const [userPoints, setUserPoints] = useState(0);
    const [timer, setTimer] = useState('');

    const sounds = [
        new Audio('assets/Cat_Meow.mp3'),
        new Audio('assets/lightsaber.mp3')
    ];

    const playRandomSound = () => {
        const randomIndex = Math.floor(Math.random() * sounds.length);
        sounds[randomIndex].play();
    };

    useEffect(() => {
        const fetchStats = async () => {
            if (!publicKey) return;

            try {
                const response = await fetch(`${apiBaseUrl}/api/getStats`);
                const data = await response.json();
                setStats(data);

                const userStat = data.find(stat => stat.publicKey === publicKey.toString());
                if (userStat) {
                    setUserPoints(userStat.points);
                } else {
                    setUserPoints(0);
                }
            } catch (error) {
                console.error('Error fetching stats:', error);
            }
        };

        const fetchTimer = async () => {
            try {
                const response = await fetch(`${apiBaseUrl}/api/timer`);
                const data = await response.json();
                setTimer(formatTime(data.timeLeft));
            } catch (error) {
                console.error('Error fetching timer:', error);
            }
        };

        fetchStats();
        fetchTimer();

        const timerInterval = setInterval(fetchTimer, 1000);

        return () => clearInterval(timerInterval);
    }, [publicKey]);

    const handleImageClick = () => {
        setCounter(prevCounter => prevCounter + 1);
        setUserPoints(prevPoints => prevPoints + 1); // Update the top-center counter

        setImagePosition({
            top: `${10 + Math.random() * 80}%`,
            left: `${10 + Math.random() * 80}%`
        });

        const randomImageNumber = Math.floor(Math.random() * 10) + 1;
        setCurrentImage(`customImage${randomImageNumber}`);

        playRandomSound();

        if ((counter + 1) % 10 === 0 && publicKey) {
            updateUserPoints();
        }
    };

    const updateUserPoints = async () => {
        try {
            const response = await fetch(`${apiBaseUrl}/api/updateStats`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ publicKey: publicKey.toString() })
            });

            if (response.ok) {
                const updatedStats = await fetch(`${apiBaseUrl}/api/getStats`).then(res => res.json());
                setStats(updatedStats);
                const userStat = updatedStats.find(stat => stat.publicKey === publicKey.toString());
                if (userStat) {
                    setUserPoints(userStat.points);
                }
            }
        } catch (error) {
            console.error('Error updating points:', error);
        }
    };

    if (!publicKey) {
        return (
            <div className="landing-page">
                <header className="header">
                    <div className="wallet-adapter">
                        <WalletMultiButton />
                    </div>
                </header>
                <div className="connect-wallet-message">Connect wallet to play the game</div>
            </div>
        );
    }

    return (
        <div className="landing-page">
            <header className="header">
                <div className="wallet-adapter">
                    <WalletMultiButton />
                </div>
                <div className="social-icons">
                    <a href="https://t.me/StarWarsCatSolana" target="_blank" rel="noopener noreferrer">
                        <img className="customImagel" src="assets/images/t.webp" alt="t" />
                    </a>
                    <a href="https://x.com/StarWarsCatSol" target="_blank" rel="noopener noreferrer">
                        <img className="customImagel" src="assets/images/x.png" alt="x" />
                    </a>
                    <a href="https://pump.fun/H57mRdGWfQF3cBrgNKREyFx4u4o1TcdwueAkniWvyf5W" target="_blank" rel="noopener noreferrer">
                        <img className="customImagel" src="assets/images/p.png" alt="P" />
                    </a>
                </div>
            </header>

            <div className="leaderboard">
                <h2>Leaderboard</h2>
                <ol>
                    {stats.slice(0, 10).map((stat, index) => (
                        <li key={stat.publicKey}>
                            {index + 1}. {formatPublicKey(stat.publicKey)}: {stat.points} points
                        </li>
                    ))}
                </ol>
                <div className="user-rank">
                    Your Points: {userPoints}
                </div>
                <div className="timer">
                    Next Solana Giveaway in {timer}
                </div>
            </div>

            <div className="counter">{userPoints}</div>
            <img
                className="customImage"
                src={`assets/images/${currentImage}.webp`}
                alt={currentImage}
                style={{ top: imagePosition.top, left: imagePosition.left }}
                onMouseEnter={handleImageClick}
            />

            <div className="token-image-container">
                <img className="token-image" src="assets/images/tokenimage1.jpg" alt="Token" />
            </div>
        </div>
    );
};

export default LandingPage;
